import React from "react";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";
import Layout from "../components/Layout";
import Colors from "../constants/Colors";
import SpacingVertical from "../components/SpacingVertical";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const Terms = (props) => {
  const { data } = props;
  const edge = data.allMarkdownRemark.edges[0];
  const { frontmatter, htmlAst } = edge.node;
  const { title, date } = frontmatter;

  return (
    <Layout override="marginStandard">
      <SpacingVertical rems={4} />
      <div className="marginFocus">
        <h1>{title}</h1>
        <p
          style={{
            ...styles.spaced,
            marginBottom: "0px",
          }}
        >
          {date}
        </p>
        <div style={styles.bodyText}>{renderAst(htmlAst)}</div>
      </div>
      <SpacingVertical rems={4} />
    </Layout>
  );
};

const styles = {
  bodyText: {
    marginBottom: "3.0rem",
  },
  spaced: {
    color: Colors.gray2,
    fontSize: "0.8rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "1.0rem",
  },
};

export default Terms;

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "terms" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`;
